import React from "react";
import ImgLogoPrestashop from "../img/logos/logo-prestashop2.svg";
import ImgLogoPrestashopWhite from "../img/logos/logo-prestashop-white.svg";
import { roundNumber } from "../utils/Math";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";

const LogoPrestashop = ({ useLink, height, alternative, ...rest }) => {
  const { t } = useTranslation();
  const Link = ({ children }) => (
    <a href="https://www.prestashop.com" target="_blank" rel="noreferrer">
      {children}
    </a>
  );

  // formula (get new width): (original width / original height) x new height = new width
  // formula (get new height): (original width / original height) x new height = new width
  // @see https://andrew.hedges.name/experiments/aspect_ratio/
  const width = (164 / 27) * height;
  const Logo = alternative === "white" ? ImgLogoPrestashopWhite : ImgLogoPrestashop;
  const Image = () => (
    <>
      <img src={Logo} alt={t("PrestaShop")} title={t("Logo de PrestaShop")} height={height} width={roundNumber(width, 0)} {...rest} />
    </>
  );

  return useLink ? (
    <Link>
      <Image />
    </Link>
  ) : (
    <Image />
  );
};

LogoPrestashop.propTypes = {
  useLink: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  alternative: PropTypes.oneOf(["white"]),
};

LogoPrestashop.defaultProps = {
  useLink: true,
  height: 16,
  alternative: null,
};

export default LogoPrestashop;
