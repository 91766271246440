import React from "react";
import Seo from "../../../components/Seo";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import BannerQuotePrestashop from "../../../components/BannerQuotePrestashop";
import LogoPrestashop from "../../../components/LogoPrestashop";
import { graphql } from "gatsby";

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        emailGeneral
      }
    }
  }
`;

const PrestashopPage = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <Seo title={t("Modules PrestaShop")} description={t("Modules officiels ComExpertise pour PrestaShop 1.6 et 1.7+.")}></Seo>

      <div className="my-5">
        <div className="container">
          <h1>
            <LogoPrestashop height={50} useLink={false} className="float-right ml-5" />
            <Trans>Modules PrestaShop</Trans>
          </h1>

          <p className="lead">
            <Trans>Modules PrestaShop développés par ComExpertise.</Trans>
          </p>

          <hr className="my-5 w-75 mx-auto" />

          <ul className="list-group mb-5">
            <Link to="/modules/prestashop/facturation.pro-monae-matpe" className="list-group-item list-group-item-action">
              <Trans>Facturation.pro (MonAE / MaTPE)</Trans>
              <small className="d-block">
                <Trans>Connecteur pour le logiciel en ligne (SaaS) de gestion commerciale et facturation : MonAE/MaTPE</Trans>
              </small>
            </Link>

            <Link to="/modules/prestashop/payments-access-restrictions" className="list-group-item list-group-item-action">
              <Trans>Payments Access</Trans>
              <small className="d-block">
                <Trans>Restrictions et droits d'accès pour les modules de paiements en fonction du contexte de navigation (Back-Office et/ou Front-Office)</Trans>
              </small>
            </Link>

            <Link to="#" className="list-group-item list-group-item-action disabled">
              <Trans>Donation</Trans>
              <small className="d-block">
                <Trans>*** Bientôt disponible ***</Trans>
              </small>
            </Link>
          </ul>
        </div>

        <BannerQuotePrestashop email={data.site.siteMetadata.emailGeneral} />
      </div>
    </>
  );
};

export default PrestashopPage;
