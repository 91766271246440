import React from "react";
import PropTypes from "prop-types";
import IconIdea from "../img/theme/idea.svg";
import IconLogoPrestaShop from "../img/logos/logo-prestashop-icon.svg";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

const BannerQuotePrestashop = ({ title: userTitle, email }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(
    graphql`
      query {
        imageMobileBgBannerQuote: file(sourceInstanceName: { eq: "img_theme" }, relativePath: { eq: "technical-pattern-1--portrait.png" }) {
          name
          childImageSharp {
            fluid(quality: 50, maxWidth: 2254, srcSetBreakpoints: [200, 400, 640, 750, 768, 1024, 1059, 1080, 1125, 1242, 1442, 1536, 2048, 2254]) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        imageDesktopBgBannerQuote: file(sourceInstanceName: { eq: "img_theme" }, relativePath: { eq: "technical-pattern-1.png" }) {
          name
          childImageSharp {
            fluid(quality: 50, maxWidth: 3840, srcSetBreakpoints: [200, 400, 640, 860, 1280, 1536, 1920, 2048, 2732, 3840]) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const title = userTitle || t("Expertise PrestaShop");
  const sourcesBgBannerQuote = [
    data.imageMobileBgBannerQuote.childImageSharp.fluid,
    {
      ...data.imageDesktopBgBannerQuote.childImageSharp.fluid,
      media: `(orientation: landscape)`,
    },
  ];

  return (
    <BackgroundImage style={{ backgroundColor: "transparent", backgroundAttachment: "fixed", backgroundPosition: "top right", backgroundSize: "cover" }} Tag="section" className="comx-banner-idea-dev" fluid={sourcesBgBannerQuote}>
      <div className="container">
        <div className="jumbotron shadow-lg my-5">
          <img className="float-xl-left mr-xl-5 mx-auto d-block" height="220" width="auto" src={IconIdea} alt="" />
          <h2 className="display-4 text-info">{title}</h2>
          <p className="lead text-info">
            <Trans>
              Vous souhaitez relier votre CRM/ERP avec votre boutique PrestaShop ? Disposer d'un tableau de reporting personnalisé, clair et efficace (comptabilité, stock, statistiques diverses...) ? Connecter votre boutique PrestaShop avec une API tierce ? (...)
              <br />
              <br />
              Notre équipe de développeurs est à votre écoute pour vous proposer et mettre en place des solutions techniques adaptées à votre métier.
            </Trans>
          </p>

          <hr className="my-4" />

          <p className="text-center mb-4">
            <Trans>Tous les e-commerçants n'ont pas les mêmes contraintes techniques, les processus métiers et le SI diffèrent et évoluent en permanence. ComExpertise vous accompagne pour vos développements spécifiques PrestaShop, dans une démarche "agile", afin de vous apporter en permanence des solutions adaptées à chacune des problématiques que vous rencontrez quotidiennement en tant qu'e-commerçant.</Trans>
          </p>

          <p className="mb-0 text-center">
            <a role="button" href={`mailto:${email}`} className="btn btn-lg btn-info text-left" target="_blank" rel="noreferrer">
              <img src={IconLogoPrestaShop} height={45} alt={"PrestaShop"} className="mr-3 float-left" />
              <Trans>Demander un devis</Trans>
              <small className="d-block">
                <Trans>Réponse sous 24H</Trans>
              </small>
            </a>
          </p>
        </div>
      </div>
    </BackgroundImage>
  );
};

BannerQuotePrestashop.propTypes = {
  title: PropTypes.string,
  email: PropTypes.string.isRequired,
};

export default BannerQuotePrestashop;
